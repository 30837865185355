import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-project-list',
  templateUrl: './project-list.component.html',
  styleUrls: ['./project-list.component.scss'],
})
export class ProjectListComponent  implements OnInit {

  projects: {image: string; name: string;}[]=[]; // Definición de la propiedad projects

  constructor() { }

  ngOnInit() {
    // Lógica para obtener proyectos y asignarlos a la propiedad projects
    this.projects = [{image: "https://fastly.picsum.photos/id/739/200/200.jpg?hmac=vjkuMOuAEhToH9GIXkmBicl7sUqPZ3k4rRHh6sQJWZ8", name: "Test project"}]; // Asigna tus proyectos aquí
  }

}

import { Component } from '@angular/core';
import { ProjectListComponent } from './project-list/project-list.component';
import { DetailProjectComponent } from './detail-project/detail-project.component';
import { HealthService } from './services/api/services'; // Asegúrate de importar el servicio desde la ubicación correcta

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  public appPages = [
    { title: 'Proyectos', url: '/project/list', icon: 'archive' },
    
  ];
  public jsonData: any; // Variable para almacenar los datos JSON obtenidos
  
  constructor(private healthService: HealthService) {} // Inyecta el servicio de salud en el constructor

  ngOnInit() {
    this.pingHealth(); // Llama al método para obtener el estado de salud cuando se inicializa el componente
  }

  pingHealth() {
    this.healthService.healthControllerPing().subscribe(
      (response: any) => {
        // Manejar la respuesta aquí
        console.log('Estado de salud:', response);
      },
      (error: any) => {
        // Manejar errores aquí
        console.error('Error al obtener el estado de salud:', error);
      }
    );
  }
}

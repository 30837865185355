import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { ProjectListComponent } from './project-list/project-list.component';
import { DetailProjectComponent } from './detail-project/detail-project.component';

const routes: Routes = [

  { path: '', redirectTo: '/project/list', pathMatch: 'full' },
  { path: 'project/list', component: ProjectListComponent },
  { path: 'project/:uuid/detail', component: DetailProjectComponent },
  
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}

<ion-header>
  <ion-toolbar>
    <ion-title>Project List</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-list>
    <!-- Iteramos sobre la lista de proyectos -->
    <ion-item *ngFor="let project of projects">
      <ion-thumbnail slot="start">
        <img [src]="project.image" alt="Project Image">
      </ion-thumbnail>
      <ion-label>
        <h2>{{ project.name }}</h2>
        
        <!-- Agrega más campos de datos según sea necesario -->
      </ion-label>
    </ion-item>
  </ion-list>
</ion-content>
